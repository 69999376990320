var validate_password_enabled = Drupal.settings.validate_password_enabled || 0;

(function ($) {
  Drupal.behaviors.signInRu = {
    attached: false,
    nodes: {},
    _DOMNodes: function (context) {
      context = context || window.document;
      this.nodes.formsContainer = $(context).find('.sign-in-component_ru__forms-container');
      this.nodes.forms = this.nodes.formsContainer.find('.forms-container__form');
      this.nodes.privacyAcceptance = this.nodes.formsContainer.find(
        'input[name=ACCEPTED_PRIVACY_POLICY]'
      );
      this.nodes.promoAcceptance = this.nodes.formsContainer.find(
        'input[name=PC_EMAIL_PROMOTIONS]'
      );
      this.nodes.triggersContainer = $(context).find('.js-legal-block');
      this.nodes.privacyAcceptanceTrigger = this.nodes.triggersContainer.find(
        '.js-social-login__social_login_terms_agreement--trigger'
      );
      this.nodes.promoAcceptanceTrigger = this.nodes.triggersContainer.find(
        '.js-social-login__email_promotions--trigger'
      );
      this.nodes.formSwitcherContainer = $(context).find('.js-form-switcher-container');
      this.nodes.formSwitchers = this.nodes.formSwitcherContainer.find('button');
    },
    _attachTriggers: function () {
      var self = this;

      self.nodes.formSwitchers.click(function (event) {
        self.nodes.formsContainer
          .find('.forms-container__form:first')
          .appendTo(self.nodes.formsContainer);
        $(this).parent().appendTo(self.nodes.formSwitcherContainer);
        event.preventDefault();
      });

      self.nodes.privacyAcceptanceTrigger.on('change', function () {
        self.nodes.privacyAcceptance.each(function () {
          if ($(this).is(':hidden')) {
            if ($(this).is(':checked')) {
              $(this).removeAttr('checked');
            } else {
              $(this).attr({
                checked: 'checked'
              });
            }
          }
        });
      });

      self.nodes.promoAcceptanceTrigger.on('change', function () {
        self.nodes.promoAcceptance.each(function () {
          if ($(this).is(':hidden')) {
            if ($(this).is(':checked')) {
              $(this).removeAttr('checked');
            } else {
              $(this).attr({
                checked: 'checked'
              });
            }
          }
        });
      });
    },
    init: function (context) {
      this._DOMNodes(context);
      this._attachTriggers();
    },
    attach: function (context) {
      if (!!this.attached) {
        return;
      }
      this.init(context);
      if (validate_password_enabled) {
        site.account.initPasswordValidationTooltip();
      }
      if (Drupal.behaviors.signIn !== undefined) {
        Drupal.behaviors.signIn.extendedRuLogin = function (context) {
          Drupal.behaviors.signInRu.init(context);
        };
      }
      this.attached = true;
    }
  };
})(jQuery);
